<template>
  <div class="wrapper">
    <component :is="layout">
      <router-view/>
    </component>
    <Loading/>
    <ServiceErrorModal/>
    <UnprocessableEntityErrorModal/>
    <OrganizationNotFoundErrorModal/>
    <AddressNotFoundErrorModal/>
    <CautionModal/>
    <vue-snotify/>
  </div>
</template>
<script>
import DefaultLayout from './components/layouts/DefaultLayout'
import AdminLayout from './components/layouts/AdminLayout'
import Loading from './components/Loading'
import ServiceErrorModal from './components/modals/ServiceErrorModal'
import UnprocessableEntityErrorModal from './components/modals/UnprocessableEntityErrorModal'
import OrganizationNotFoundErrorModal from './components/modals/OrganizationNotFoundErrorModal'
import AddressNotFoundErrorModal from './components/modals/AddressNotFoundErrorModal'
import CautionModal from './components/modals/CautionModal'
export default {
  name: 'App',
  components: {
    DefaultLayout,
    AdminLayout,
    Loading,
    ServiceErrorModal,
    UnprocessableEntityErrorModal,
    OrganizationNotFoundErrorModal,
    AddressNotFoundErrorModal,
    CautionModal,
  },
  computed: {
    layout () {
      return this.$route.meta.layout || 'DefaultLayout'
    }
  }
}
</script>
<style>
#app {
  font-family: Roboto,Arial,-apple-system,BlinkMacSystemFont,"Segoe UI",Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-size: 18px;
  color: #1f1f1f;
}
.container-expand {
  padding: 0 40px;
}
</style>
