<template>
  <section class="catalog__section">
    <div class="catalog__container">
      <h2 class="catalog__title">{{ $lang.app.frequently_asked_questions }}</h2>
      <div class="catalog__list">
        <div class="catalog__item-wrap">
          <div class="catalog__item">
            <router-link to="/faq?type=PRESCHOOL" class="catalog__link">
              <h3 class="catalog__item-title">{{this.$lang.app.preschool }}</h3>
              <p class="catalog__item-descr">{{ this.$lang.app.preschool_faq_description }}</p>
            </router-link>
          </div>
        </div>
        <div class="catalog__item-wrap">
          <div class="catalog__item">
            <router-link to="/faq?type=SCHOOL" class="catalog__link">
              <h3 class="catalog__item-title">{{ this.$lang.app.school }}</h3>
              <p class="catalog__item-descr">{{ this.$lang.app.school_faq_description }}</p>
            </router-link>
          </div>
        </div>
        <div class="catalog__item-wrap">
          <div class="catalog__item">
            <router-link to="/law" class="catalog__link">
              <h3 class="catalog__item-title">{{ this.$lang.app.regulatory_legal_framework }}</h3>
              <p class="catalog__item-descr">{{ this.$lang.app.regulatory_legal_framework_description }}</p>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      items: [
        {
          title: this.$lang.app.preschool,
          description: this.$lang.app.preschool_faq_description,
          link: '/faq?type=PRESCHOOL'
        },
        {
          title: this.$lang.app.school,
          description: this.$lang.app.school_faq_description,
          link: '/faq?type=SCHOOL'
        },
        {
          title: this.$lang.app.regulatory_legal_framework,
          description: this.$lang.app.regulatory_legal_framework_description,
          link: '/law'
        }
      ]
    }
  }
}
</script>
<style>
.catalog__section {
  padding: 45px 0;
}
.catalog__container {
  width: 1560px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 20px;
  padding-right: 20px;
}
.catalog__title {
  text-align: center;
  margin-bottom: 50px;
  font-size: 32px;
  font-weight: 600;
}
.catalog__list {
  display: flex;
}
.catalog__item-wrap {
  width: 33.33%;
  padding: 0 15px;
  margin-bottom: 30px;
}
.catalog__item {
  box-sizing: border-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  padding: 25px 30px;
  border-radius: 4px;
  transition: box-shadow .3s cubic-bezier(.25,.8,.25,1),background .3s ease-in-out;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
}
.catalog__item:hover {
  box-shadow: 0 7px 20px rgb(0 0 0 / 12%), 0 3px 10px rgb(0 0 0 / 9%);
}
.catalog__link {
  position: relative;
  line-height: 1.5em;
  transition: color .2s ease-in-out;
  text-decoration: none;
}
.catalog__item-title {
  margin-bottom: 10px;
  color: #367db7;
  font-size: 20px;
  transition: color .2s ease-in-out;
  font-weight: 600;
}
.catalog__item-descr {
  margin: 10px 0 0;
  color: #111;
  font-size: 16px;
}
@media screen and (max-width: 1600px) {
  .catalog__container {
    width: 100%;
  }
}
@media screen and (max-width: 920px) {
  .catalog__list {
    flex-wrap: wrap;
  }
  .catalog__item-wrap {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .catalog__title {
    margin-bottom: 40px;
    font-size: 28px;
  }
}
</style>