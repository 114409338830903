import { reactive } from "vue"
import { setLocale } from 'yup';
let lang = null;

export default {
    install(app, options) {

        lang = reactive({
            $current: localStorage.getItem('lang') || options.lang,
            $setLang(locale) {
                this.$current = locale;
                localStorage.setItem('lang', locale, Infinity)
                setLocale(options.validationLocales[locale])
                this.app = options.locales[this.$current] || options.locales.ru
            },
            $translate(langOptions) {
                return langOptions[this.$current]
            },
            app: {}
        })

        lang.$setLang(lang.$current)

        app.config.globalProperties.$lang = lang
    }
}

export function useLang() {
    return lang;
}