<template>
  <section class="handbook__section">
    <div class="handbook__container">
      <h2 class="handbook__title">{{ $lang.app.video_description }}</h2>
      <div class="handbook__list">
        <div class="handbook_video">
          <iframe type="text/html" width="1200" height="500"
                  :src="getLink($lang.app.video_link)"/>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {}
  },
  methods: {
    parsedUrl(url) {
      return new URL(url)
    },
    isYoutubeVideo(url) {
      return this.parsedUrl(url).searchParams.has('v')
    },
    getLink(url) {
      return `https://www.youtube.com/embed/${this.parsedUrl(url).searchParams.get('v')}?origin=${location.origin}&fs=0&loop=1`
    },
  }

}
</script>
<style>
.handbook__section {
  padding: 45px 0;
}

.handbook__container {
  width: 1560px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 20px;
  padding-right: 20px;
}

.handbook__title {
  text-align: center;
  margin-bottom: 50px;
  font-size: 32px;
  font-weight: 600;
}

.handbook__list {
  display: flex;
}

.handbook_video {
  display: flex;
  text-align: center;
  margin-right: auto;
  margin-left: auto;
}

@media screen and (max-width: 1600px) {
  .handbook__container {
    width: 100%;
  }
}

@media screen and (max-width: 920px) {
  .handbook__list {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 768px) {
  .handbook__title {
    margin-bottom: 40px;
    font-size: 28px;
  }
}
@media screen and (max-width: 1100px) {
  iframe {
    width: auto;
    height: auto;
  }
}
</style>