export default {
    state: {
        serviceErrorModal: false,
        unprocessableEntityErrorModal: false,
        organizationNotFoundErrorModal: false,
        addressNotFoundErrorModal: false,
        errorMessage: '',
    },
    mutations: {
        showServiceErrorModal(state) {
            state.serviceErrorModal = true
        },
        hideServiceErrorModal(state) {
            state.serviceErrorModal = false
        },
        showUnprocessableEntityErrorModal(state, payload) {
            state.errorMessage = payload.message
            state.unprocessableEntityErrorModal = true
        },
        hideUnprocessableEntityErrorModal(state) {
            state.errorMessage = ''
            state.unprocessableEntityErrorModal = false
        },
        showOrganizationNotFoundErrorModal(state) {
            state.organizationNotFoundErrorModal = true
        },
        hideOrganizationNotFoundErrorModal(state) {
            state.organizationNotFoundErrorModal = false
        },
        showAddressNotFoundErrorModal(state) {
            state.addressNotFoundErrorModal = true
        },
        hideAddressNotFoundErrorModal(state) {
            state.addressNotFoundErrorModal = false
        },
    },
    getters: {
        getServiceErrorModal: state => state.serviceErrorModal,
        getUnprocessableEntityErrorModal: state => state.unprocessableEntityErrorModal,
        getOrganizationNotFoundErrorModal: state => state.organizationNotFoundErrorModal,
        getAddressNotFoundErrorModal: state => state.addressNotFoundErrorModal,
        getErrorMessage: state => state.errorMessage,
    }
}