import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'

import snotify from 'vue3-snotify';
import 'vue3-snotify/dist/style.css';
import jump from 'jump.js'
import moment from 'moment-timezone'
moment.tz.setDefault('Asia/Bishkek')
import mitt from 'mitt';
import Multiselect from '@vueform/multiselect'
import "@vueform/multiselect/themes/default.css";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import axios from './helpers/axios'
import lang from './helpers/vue-lang'
import ru from './assets/locales/ru'
import kg from './assets/locales/kg'
import ruValidation from './assets/locales/validations/ru'
import kgValidation from './assets/locales/validations/kg'

import { VueReCaptcha } from 'vue-recaptcha-v3'

const app = createApp(App)

app.use(store)
app.use(router)
app.use(snotify)
app.use(VueSweetalert2);
app.config.globalProperties.$jump = jump
app.config.globalProperties.$moment = moment
app.config.globalProperties.$emitter = mitt()
app.config.globalProperties.$axios = axios
app.use(lang, { lang: 'kg', locales: { ru, kg }, validationLocales: {ru: ruValidation, kg: kgValidation} })
app.use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_V3_SITE_KEY })
app.component('Multiselect', Multiselect)
app.mount('#app')