export default {
    mixed: {
        required: 'Толтурулушу керек',
    },
    string: {
        required: 'Толтурулушу керек',
        min: 'Өтө кыска (кеминде ${min} белги)',
        matches: 'Туура эмес формат',
        email: 'Туура эмес электрондук почта дареги форматы',
    },
    date: {
        min: 'Талаа ${min} кечирээк болушу керек',
        max: 'Талаа ${max} чейин болушу керек',
    }
}