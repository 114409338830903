import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import store from "../store"

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/application-school',
    name: 'ApplicationSchool',
    component: () => import(/* webpackChunkName: "applicationSchool" */ '../views/ApplicationSchool.vue')
  },
  {
    path: '/application-preschool',
    name: 'ApplicationPreschool',
    component: () => import(/* webpackChunkName: "applicationPreschool" */ '../views/ApplicationPreschool.vue')
  },
  {
    path: '/application-status/school',
    name: 'ApplicationStatusSchool',
    component: () => import(/* webpackChunkName: "applicationStatusSchool" */ '../views/application-status/School.vue')
  },
  {
    path: '/application-status/preschool',
    name: 'ApplicationStatusPreschool',
    component: () => import(/* webpackChunkName: "applicationStatusPreschool" */ '../views/application-status/Preschool.vue')
  },
  {
    path: '/relationship-check',
    name: 'RelationshipCheck',
    component: () => import(/* webpackChunkName: "relationshipCheck" */ '../views/RelationshipCheck.vue')
  },
  {
    path: '/organization-check',
    name: 'OrganizationCheck',
    component: () => import(/* webpackChunkName: "organizationCheck" */ '../views/OrganizationCheck.vue')
  },
  {
    path: '/faq',
    name: 'Faq',
    component: () => import(/* webpackChunkName: "Faq" */ '../views/Faq.vue')
  },
  {
    path: '/law',
    name: 'Law',
    component: () => import(/* webpackChunkName: "Law" */ '../views/Law.vue')
  },
  {
    path: '/statistics',
    name: 'Statistics',
    component: () => import(/* webpackChunkName: "Statistics" */ '../views/statistics/Index.vue'),
    children: [
      {
        path: 'main',
        name: 'StatisticsMain',
        component: () => import(/* webpackChunkName: "StatisticsMain" */ '../views/statistics/Main.vue')
      },
      {
        path: '',
        name: 'StatisticsFilters',
        component: () => import(/* webpackChunkName: "StatisticsFilters" */ '../views/statistics/Filters.vue')
      }
    ]
  },
  {
    path: '/school-statistics',
    name: 'StatisticsSchool',
    component: () => import(/* webpackChunkName: "Statistics" */ '../views/statistics/school-statistics/Index.vue'),
    children: [
      {
        path: 'main',
        name: 'StatisticsSchoolMain',
        component: () => import(/* webpackChunkName: "StatisticsMain" */ '../views/statistics/school-statistics/Main.vue')
      },
      {
        path: '',
        name: 'StatisticsSchoolFilters',
        component: () => import(/* webpackChunkName: "StatisticsFilters" */ '../views/statistics/school-statistics/Filters.vue')
      }
    ]
  },
  {
    path: '/preschool-statistics',
    name: 'StatisticsPreschool',
    component: () => import(/* webpackChunkName: "Statistics" */ '../views/statistics/preschool-statistics/Index.vue'),
    children: [
      {
        path: 'main',
        name: 'StatisticsPreschoolMain',
        component: () => import(/* webpackChunkName: "StatisticsMain" */ '../views/statistics/preschool-statistics/Main.vue')
      },
      {
        path: '',
        name: 'StatisticsPreschoolFilters',
        component: () => import(/* webpackChunkName: "StatisticsFilters" */ '../views/statistics/preschool-statistics/Filters.vue')
      }
    ]
  },
  {
    path: '/auth',
    name: 'Auth',
    component: () => import(/* webpackChunkName: "Auth" */ '../views/auth/Index.vue'),
    children: [
      {
        path: 'login-parent',
        name: 'LoginParent',
        component: () => import(/* webpackChunkName: "loginParent" */ '../views/auth/LoginParent.vue')
      },
      {
        path: 'forgot-password',
        name: 'ForgotPassword',
        component: () => import(/* webpackChunkName: "forgotPassword" */ '../views/auth/ForgotPassword.vue')
      },
      {
        path: 'forgot-email',
        name: 'ForgotEmail',
        component: () => import(/* webpackChunkName: "forgotEmail" */ '../views/auth/ForgotEmail.vue')
      },
      {
        path: 'send-to-email',
        name: 'SendToEmail',
        component: () => import(/* webpackChunkName: "sendToEmail" */ '../views/auth/SendToEmail.vue')
      },
      {
        path: 'email-success',
        name: 'EmailSuccess',
        component: () => import(/* webpackChunkName: "emailSuccess" */ '../views/auth/EmailSuccess.vue')
      }
    ]
  },
  {
    path: '/auth-ekyzmat/login',
    name: 'AuthEkyzmat',
    component: () => import(/* webpackChunkName: "authEkyzmat" */ '../views/AuthEkyzmat.vue')
  },
  {
    path: '/admin',
    name: 'Admin',
    meta: { layout: 'AdminLayout' },
    component: () => import(/* webpackChunkName: "Admin" */ '../views/admin/Admin.vue'),
    children: [
      {
        path: '',
        name: 'Dashboard',
        component: () => import(/* webpackChunkName: "Dashboard" */ '../views/admin/Dashboard.vue')
      },
      {
        path: 'application-school',
        name: 'AdminApplicationSchool',
        component: () => import(/* webpackChunkName: "UsersIndex" */ '../views/ApplicationSchool.vue')
      },
      {
        path: 'users',
        name: 'Users',
        component: () => import(/* webpackChunkName: "UsersIndex" */ '../views/admin/users/Index.vue'),
        children: [
          {
            path: '',
            name: 'UsersList',
            component: () => import(/* webpackChunkName: "UsersList" */ '../views/admin/users/List.vue'),
          },
          {
            path: ':id',
            name: 'UsersDetail',
            component: () => import(/* webpackChunkName: "UsersDetail" */ '../views/admin/users/Detail.vue'),
          },
        ]
      },
      {
        path: 'reports',
        name: 'Reports',
        component: () => import(/* webpackChunkName: "ReportsIndex" */ '../views/admin/reports/Index.vue'),
      },
      {
        path: 'statuses',
        name: 'Statuses',
        component: () => import(/* webpackChunkName: "StatusesIndex" */ '../views/admin/statuses/Index.vue'),
      },
      {
        path: 'faq',
        name: 'AdminFaq',
        component: () => import(/* webpackChunkName: "FaqIndex" */ '../views/admin/faq/Index.vue'),
        children: [
          {
            path: '',
            name: 'FaqList',
            component: () => import(/* webpackChunkName: "FaqList" */ '../views/admin/faq/List.vue'),
          },
          {
            path: 'create',
            name: 'FaqCreate',
            component: () => import(/* webpackChunkName: "FaqCreate" */ '../views/admin/faq/Create.vue'),
          },
          {
            path: ':id/edit',
            name: 'FaqEdit',
            component: () => import(/* webpackChunkName: "FaqEdit" */ '../views/admin/faq/Edit.vue'),
          },
          {
            path: ':id',
            name: 'FaqDetail',
            component: () => import(/* webpackChunkName: "FaqDetail" */ '../views/admin/faq/Detail.vue'),
          },
        ]
      },
      {
        path: 'applicants',
        name: 'AdminApplicants',
        component: () => import(/* webpackChunkName: "ApplicantsIndex" */ '../views/admin/applicants/Index.vue'),
        children: [
          {
            path: '',
            name: 'ApplicantsList',
            component: () => import(/* webpackChunkName: "ApplicantsList" */ '../views/admin/applicants/List.vue'),
          },
          {
            path: 'create',
            name: 'ApplicantsCreate',
            component: () => import(/* webpackChunkName: "ApplicantsCreate" */ '../views/admin/applicants/Create.vue'),
          },
          {
            path: ':id/edit',
            name: 'ApplicantsEdit',
            component: () => import(/* webpackChunkName: "ApplicantsEdit" */ '../views/admin/applicants/Edit.vue'),
          },
          {
            path: ':id',
            name: 'ApplicantsDetail',
            component: () => import(/* webpackChunkName: "ApplicantsDetail" */ '../views/admin/applicants/Detail.vue'),
          },
        ]
      },
      {
        path: 'law',
        name: 'AdminLaw',
        component: () => import(/* webpackChunkName: "LawIndex" */ '../views/admin/law/Index.vue'),
        children: [
          {
            path: '',
            name: 'LawList',
            component: () => import(/* webpackChunkName: "LawList" */ '../views/admin/law/List.vue'),
          },
          {
            path: 'create',
            name: 'LawCreate',
            component: () => import(/* webpackChunkName: "LawCreate" */ '../views/admin/law/Create.vue'),
          },
          {
            path: ':id/edit',
            name: 'LawEdit',
            component: () => import(/* webpackChunkName: "LawEdit" */ '../views/admin/law/Edit.vue'),
          },
          {
            path: ':id',
            name: 'LawDetail',
            component: () => import(/* webpackChunkName: "LawDetail" */ '../views/admin/law/Detail.vue'),
          },
        ]
      },
      {
        path: 'help',
        name: 'AdminHelp',
        component: () => import(/* webpackChunkName: "HelpIndex" */ '../views/admin/help/Index.vue'),
        children: [
          {
            path: '',
            name: 'HelpList',
            component: () => import(/* webpackChunkName: "HelpList" */ '../views/admin/help/List.vue'),
          },
          {
            path: 'create',
            name: 'HelpCreate',
            component: () => import(/* webpackChunkName: "HelpCreate" */ '../views/admin/help/Create.vue'),
          },
          {
            path: ':id/edit',
            name: 'HelpEdit',
            component: () => import(/* webpackChunkName: "HelpEdit" */ '../views/admin/help/Edit.vue'),
          },
          {
            path: ':id',
            name: 'HelpDetail',
            component: () => import(/* webpackChunkName: "HelpDetail" */ '../views/admin/help/Detail.vue'),
          },
        ]
      },
      {
        path: 'deadlines',
        name: 'AdminDeadlines',
        component: () => import(/* webpackChunkName: "DeadlinesIndex" */ '../views/admin/deadlines/Index.vue'),
        children: [
          {
            path: '',
            name: 'DeadlinesList',
            component: () => import(/* webpackChunkName: "DeadlinesList" */ '../views/admin/deadlines/List.vue'),
          },
          {
            path: 'create',
            name: 'DeadlinesCreate',
            component: () => import(/* webpackChunkName: "DeadlinesCreate" */ '../views/admin/deadlines/Create.vue'),
          },
          {
            path: ':id/edit',
            name: 'DeadlinesEdit',
            component: () => import(/* webpackChunkName: "DeadlinesEdit" */ '../views/admin/deadlines/Edit.vue'),
          },
          {
            path: ':id',
            name: 'DeadlinesDetail',
            component: () => import(/* webpackChunkName: "DeadlinesDetail" */ '../views/admin/deadlines/Detail.vue'),
          },
        ]
      },
      {
        path: 'groups',
        name: 'Groups',
        component: () => import(/* webpackChunkName: "GroupsIndex" */ '../views/admin/groups/Index.vue'),
        children: [
          {
            path: '',
            name: 'GroupsList',
            component: () => import(/* webpackChunkName: "GroupsList" */ '../views/admin/groups/List.vue'),
          },
          {
            path: 'create',
            name: 'GroupsCreate',
            component: () => import(/* webpackChunkName: "GroupsCreate" */ '../views/admin/groups/Create.vue'),
          },
          {
            path: ':id/edit',
            name: 'GroupsEdit',
            component: () => import(/* webpackChunkName: "GroupsEdit" */ '../views/admin/groups/Edit.vue'),
          },
          {
            path: ':id',
            name: 'GroupsDetail',
            component: () => import(/* webpackChunkName: "GroupsDetail" */ '../views/admin/groups/Detail.vue'),
          },
        ]
      },
      {
        path: 'applications',
        name: 'Applications',
        component: () => import(/* webpackChunkName: "ApplicationsIndex" */ '../views/admin/applications/Index.vue'),
        children: [
          {
            path: '',
            name: 'ApplicationsList',
            component: () => import(/* webpackChunkName: "ApplicationsList" */ '../views/admin/applications/List.vue'),
          },
          {
            path: 'create',
            name: 'ApplicationsCreate',
            component: () => import(/* webpackChunkName: "ApplicationsCreate" */ '../views/admin/applications/Create.vue'),
          },
          {
            path: 'create-by-children\'s-committee',
            name: 'ApplicationsCreateByKdd',
            component: () => import(/* webpackChunkName: "ApplicationsCreate" */ '../views/admin/applications/CreateByKdd.vue'),
          },
          {
            path: ':id',
            name: 'ApplicationsDetail',
            component: () => import(/* webpackChunkName: "ApplicationsDetail" */ '../views/admin/applications/Detail.vue'),
          }
        ]
      },
      {
        path: 'quotas',
        name: 'Quotas',
        component: () => import(/* webpackChunkName: "GroupsIndex" */ '../views/admin/quotas/Index.vue'),
        children: [
          {
            path: '',
            name: 'QuotasList',
            component: () => import(/* webpackChunkName: "QuotasList" */ '../views/admin/quotas/List.vue'),
          },
          {
            path: 'create',
            name: 'QuotasCreate',
            component: () => import(/* webpackChunkName: "QuotasCreate" */ '../views/admin/quotas/Create.vue'),
          },
          {
            path: ':id/edit',
            name: 'QuotasEdit',
            component: () => import(/* webpackChunkName: "QuotasEdit" */ '../views/admin/quotas/Edit.vue'),
          },
          {
            path: ':id',
            name: 'QuotasDetail',
            component: () => import(/* webpackChunkName: "QuotasDetail" */ '../views/admin/quotas/Detail.vue'),
          },
        ]
      },
    ],
    beforeEnter(to, from, next) {
      if (store.getters.getToken) {
        store.dispatch('fetchUser').then(() => {
          next()
        }).catch(error => {
          if(error.response.status === 401) {
            next({
              name: "Home"
            })
          }
        })
      } else {
        next({
          name: "Home"
        });
      }
    }
  },
  { path: "/:catchAll(.*)", redirect: '/' },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if(!store.getters.getDeadline) {
    store.dispatch('fetchDeadline').finally(() => {
      next()
    })
  } else {
    next()
  }
})

export default router
