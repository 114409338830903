<template>
  <div>
    <div v-show="$store.getters.getOrganizationNotFoundErrorModal" class="modal">
      <div class="modal-background" @click="$store.commit('hideOrganizationNotFoundErrorModal')"></div>
      <div class="modal-content">
        <div class="service-error">
          <div class="service-error-title">
            <i class="bi-exclamation-triangle-fill"></i>
          </div>
          <div class="error-content" v-html="$lang.app.organization_not_found_error"></div>
          <div class="controls">
            <button @click="$store.commit('hideOrganizationNotFoundErrorModal')">ОК</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
@import '../../assets/styles/modal.css';
.error-content {
  margin-bottom: 40px;
}
.service-error .service-error-title {
  display: flex;
  justify-content: center;
  align-items: center;
}
.service-error .service-error-title i {
  margin-bottom: 1rem;
  flex-shrink: 0;
  color: red;
  font-size: 48px;
  display: inline-block;
  margin-right: 20px;
}
.service-error .controls {
  display: flex;
  justify-content: center;
}
.service-error .controls button {
  height: 46px;
  line-height: 46px;
  text-align: center;
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  border-radius: 5px;
  display: inline-block;
  vertical-align: middle;
  background: linear-gradient(to bottom, #63b8d8 0%, #367db7 100%);
  border: none;
  padding: 0 24px;
}
.service-error .controls button:hover {
  background: linear-gradient(to bottom, #74e8fc 0%, #375fb7 100%);
}
</style>