<template>
  <div class="home">
    <Promo/>
    <Handbook/>
    <Catalog/>
    <HowItWorks/>
<!--    <WriteUs/>-->
  </div>
</template>
<script>
import Promo from '@/components/home/Promo.vue'
import Handbook from '@/components/home/Handbook.vue'
import Catalog from '@/components/home/Catalog.vue'
import HowItWorks from '@/components/home/HowItWorks.vue'
// import WriteUs from '@/components/home/WriteUs.vue'
export default {
  name: 'Home',
  components: {
    Promo,
    Handbook,
    Catalog,
    HowItWorks,
    // WriteUs,
  }
}
</script>
