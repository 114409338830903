<template>
  <header class="header-border-bottom">
    <div class="header-sign-in">
      <div class="container-expand">
        <div class="header-contacts-wrapper">
<!--          <div class="header-contacts">-->
<!--            <p>{{ $lang.app.helplines }}</p>-->
<!--            <div class="phone-icon-wrapper">-->
<!--              <i class="bi-telephone-fill"></i>-->
<!--            </div>-->
<!--            <div>-->
<!--              <p>+996</p>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="header-contacts">-->
<!--            <p>Call Center</p>-->
<!--            <div class="phone-icon-wrapper">-->
<!--              <i class="bi-telephone-fill"></i>-->
<!--            </div>-->
<!--            <div>-->
<!--              <p></p>-->
<!--            </div>-->
<!--          </div>-->
        </div>
        <div class="header-top-right">
          <div class="header-sign-in-link-container">
            <a @click.prevent="logout" class="header-sign-in-link">
              <i class="bi-box-arrow-in-left"></i>{{ $lang.app.logout }}
            </a>
          </div>
          <div class="header-sign-in-languages">
            <a class="header-sign-in-lang-link"
               :class="{'active': $lang.$current === 'ru'}"
               @click.prevent="$lang.$setLang('ru')">
              <span>{{ $lang.app.ru }}</span>
            </a>
            <a class="header-sign-in-lang-link"
               :class="{'active': $lang.$current === 'kg'}"
               @click.prevent="$lang.$setLang('kg')">
              <span>{{ $lang.app.kg }}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <nav class="main-nav">
      <div class="container-expand">
        <div class="user-info-wrapper">
          <div>
            <div v-if="$store.getters.isEmployee" class="organization-info">
              {{ accountTitle }}
            </div>
          </div>
          <div v-if="$store.getters.getUser" class="user-info">
            <div class="user-name">{{ $store.getters.getUser.lastName }} {{ $store.getters.getUser.firstName }}</div>
            <div class="user-school">{{ $store.getters.getPlaceOfWork }}</div>
          </div>
        </div>
        <div class="main-nav-links-mobile">
          <a @click.prevent="logout" class="header-sign-in-link">
            <i class="bi-box-arrow-in-left"></i>
            <span>{{ $lang.app.logout }}</span>
          </a>
          <button v-if="!mobileMenu" class="navBarBtn"
                  @click="mobileMenu = true">
            <i class="bi-list"></i>
          </button>
          <button v-else class="navBarBtn"
                  @click="mobileMenu = false">
            <i class="bi-x"></i>
          </button>
        </div>
        <div v-show="mobileMenu"
             class="main-nav-links-navbar-mobile" id="block">
          <ul>
            <li>
              <a class="header-sign-in-lang-link"
                 :class="{'active': $lang.$current === 'ru'}"
                 @click.prevent="$lang.$setLang('ru')">
                <span>{{ $lang.app.ru }}</span>
              </a>
            </li>
            <li>
              <a class="header-sign-in-lang-link"
                 :class="{'active': $lang.$current === 'kg'}"
                 @click.prevent="$lang.$setLang('kg')">
                <span>{{ $lang.app.kg }}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>
<script>
export default {
  name: 'Header',
  data() {
    return {
      mobileMenu: false,
    }
  },
  computed: {
    accountTitle() {
      if(this.$store.getters.isSchoolDirector || this.$store.getters.isSchoolEmployee) {
        return this.$lang.app.account_school;
      }
      if(this.$store.getters.isPreschoolDirector || this.$store.getters.isPreschoolEmployee) {
        return this.$lang.app.account_preschool;
      }
      return ''
    }
  },
  methods: {
    logout() {
      localStorage.removeItem('token')
      this.$store.commit('logout')
      this.$router.push('/')
    }
  }
}
</script>
<style scoped>
.organization-info {
  font-weight: bold;
  white-space: nowrap;
}
.user-info-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.user-info-wrapper .user-info {
  max-width: 555px;
  text-align: end;
}
.user-info .user-name {
  font-weight: bold;
  margin-bottom: 5px;
}
.user-info .user-school {
  line-height: 1.2;
  font-size: 16px;
}
.header-border-bottom {
  border-bottom: 1px solid lightgray;
}
a {
  color: #1f1f1f;
  text-decoration: none;
}
.header-top-right {
  display: flex;
}
.header-sign-in {
  background: #e8e8e8;
}
.header-sign-in .container-expand {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-contacts-wrapper {
  display: flex;
  padding: 8px 0;
}
.header-contacts-wrapper .header-contacts {
  margin-right: 20px;
  display: flex;
  align-items: center;
}
.header-contacts-wrapper .header-contacts p {
  font-size: 14px;
  margin-right: 10px;
  margin-bottom: 0;
  font-family: 'PT Sans', sans-serif;
}
.header-contacts-wrapper .header-contacts .phone-icon-wrapper {
  width: 36px;
  height: 36px;
  border: 2px solid black;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.header-sign-in-link-container {
  display: flex;
  align-items: center;
}
.header-sign-in-link {
  font-size: 14px;
  color: #1f1f1f;
  text-decoration: none;
  vertical-align: middle;
  padding-right: 35px;
  border-right: 1px solid #888;
  display: flex;
  align-items: center;
  transition: 0.5s;
  cursor: pointer;
}
.header-sign-in-link i {
  vertical-align: middle;
  color: #888;
  font-size: 18px;
  margin-right: 10px;
}
.header-sign-in-languages {
  display: flex;
  height: 100%;
}
.header-sign-in-lang-link {
  cursor: pointer;
  margin-left: 30px;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
}
.header-sign-in-lang-link span {
  font-size: 14px;
}
.header-sign-in-lang-link.active span {
  color: #4c9ac7;
}
.main-nav .container-expand {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 40px;
  position: relative;
  z-index: 20;
  background: #fff;
}
.main-nav-logo div {
  width: 100%;
  height: 80px;
}
.main-nav-links-mobile {
  display: none;
}
.main-nav-links-navbar-mobile {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: #fff;
  border-top: 1px solid #dcdcdc;
  z-index: -5;
  transition: 1s;
}
.main-nav-links-navbar-mobile ul {
  padding: 35px 0 0;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  justify-content: space-between;
  border-bottom: 1px solid #dcdcdc;
}
.main-nav-links-navbar-mobile ul li {
  margin: 0 0 40px 0;
  padding: 0 45px;
  list-style: none;
  width: 50%;
  text-align: left;
}
.main-nav-links-navbar-mobile ul li .header-sign-in-lang-link {
  cursor: pointer;
  margin-left: 0;
  text-decoration: none;
  display: block;
  text-align: left;
}
.main-nav-links-navbar-mobile ul li a {
  color: #1f1f1f;
  text-decoration: none;
}
.main-nav-links ul {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  width: 100%;
}
.main-nav-links ul li {
  list-style: none;
  margin: 0 0 8px 30px;
  padding: 0;
  display: flex;
  align-items: center;
}
.main-nav-links ul li a {
  color: #1f1f1f;
  text-decoration: none;
  border-bottom: 2px solid transparent;
}
.main-nav-links ul li a:hover {
  border-bottom: 2px solid #4c9ac7;
}
.main-nav-links-navbar-mobile ul li .header-sign-in-lang-link span {
  font-size: inherit;
}
@media screen and (max-width: 1200px) {
  .header-sign-in {
    display: none;
  }
  .header-sign-in-link {
    font-size: 16px;
    color: #888;
    border-right: none;
  }
  .main-nav .container-expand {
    padding: 10px 15px;
  }
  .main-nav-links-mobile {
    display: flex;
    margin-left: 20px;
  }
  .main-nav-links-mobile i {
    font-size: 36px;
  }
  .navBarBtn {
    background: transparent;
    border: none;
    color: #888;
  }
  .main-nav-links-mobile i {
    font-size: 36px;
  }
}
@media screen and (max-width: 767px) {
  .user-info-wrapper {
    flex-wrap: wrap;
  }
  .header-sign-in-link {
    padding-right: 20px;
  }
  .header-sign-in-link i {
    margin-right: 0;
  }
  .main-nav-links-navbar-mobile ul li {
    width: 100%;
  }
  .header-sign-in-link span {
    display: none;
  }
  .user-info-wrapper .user-info {
    width: 100%;
    text-align: start;
  }
}
@media screen and (max-width: 479px) {
  .main-nav-links-navbar-mobile {
    top: 58px;
  }
  .main-nav-logo div {
    width: 285px;
  }
  .header-sign-in-link {
    padding-right: 10px;
  }
  .main-nav-links-mobile i, .main-nav-links-mobile i {
    font-size: 24px;
  }
  .main-nav-links-navbar-mobile ul li {
    padding: 0 0 0 40px;
  }
}
</style>