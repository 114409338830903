<template>
  <div class="default-layout">
    <div class="content">
      <Header/>
      <slot />
    </div>
    <Footer/>
  </div>
</template>
<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
export default {
  name: 'DefaultLayout',
  components: {
    Header,
    Footer
  },
}
</script>
<style>
.default-layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.default-layout .content {
  flex: 1 0 auto;
}
</style>