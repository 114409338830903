<template>
  <footer>
    <div class="footer-bottom">
      <div class="container-expand">
        <div class="footer-social">
          <div> © 2021 <a href="https://edu.gov.kg/">{{ $lang.app.education_and_science_ministry }}</a></div>
        </div>
        <div class="footer-social">
          <div class="developer">
            {{ $lang.app.developer }}:  <a href="https://infocom.kg/">{{ $lang.app.infocom }}</a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<style scoped>
.footer-bottom a {
  color: black;
}
.footer-nav-container .container-expand {
  padding: 20px 15px;
  display: flex;
  justify-content: center;
}
.footer-nav-container .container-expand .footer-nav ul {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0;
}
.footer-nav-container .container-expand .footer-nav ul li {
  margin: 0;
  padding: 0;
  list-style: none;
}
.footer-nav-container .container-expand .footer-nav ul li a {
  color: #1f1f1f;
  text-decoration: none;
}
.footer-contacts .container-expand {
  padding: 20px 40px;
  display: flex;
  justify-content: center;
}
.footer-contacts .container-expand .footer-contacts-items .footer-contacts-item p {
  padding: 0;
  margin: 0;
}
.footer-contacts .container-expand .footer-contacts-items .footer-contacts-item span {
  font-weight: bold;
}
.footer-bottom {
  background: #b8b8b8;
  font-size: 16px;
}
.footer-bottom .container-expand {
  padding: 20px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer-bottom .container-expand .footer-social {
  display: flex;
  justify-content: space-between;
}
.footer-bottom .container-expand .footer-social i {
  font-size: 32px;
  color: #1f1f1f;
  text-decoration: none;
}
.footer-bottom .container-expand span {
  font-size: 14px;
}
.footer-nav-container .container-expand .footer-nav ul li a:hover {
  text-decoration: underline;
}
.footer-bottom .container-expand .footer-social a:hover {
  opacity: 0.75;
}
@media screen and (max-width: 1200px) {
  .footer-nav-container .container-expand {
    padding: 35px 15px 20px;
  }
  .footer-nav-container .container-expand .footer-nav ul {
    align-items: flex-start;
    flex-wrap: wrap;
  }
  .footer-nav-container .container-expand .footer-nav ul li {
    margin-bottom: 25px;
    padding: 0 40px;
    width: 33.3333%;
  }
  .footer-bottom .container-expand {
    padding: 0;
    flex-wrap: wrap;
  }
  .footer-bottom .container-expand .footer-social {
    padding: 20px;
    justify-content: center;
    width: 100%;
    margin: 0;
    border-bottom: 1px solid #888;
    text-align: center;
  }
  .footer-bottom .container-expand .footer-social i {
    font-size: 48px;
  }
  .footer-bottom .container-expand span {
    padding: 20px 15px;
  }
}
@media screen and (max-width: 767px) {
  .footer-nav-container .container-expand .footer-nav ul li {
    width: 50%;
    padding: 0 30px;
  }
  .footer-bottom .container-expand .footer-social i {
    font-size: 36px;
  }
  .footer-bottom .container-expand span {
    font-size: 12px;
  }
}
@media screen and (max-width: 479px) {
  .footer-nav-container .container-expand .footer-nav ul li {
    width: 100%;
  }
  .footer-nav-container .container-expand .footer-nav ul li a {
    font-size: 16px;
  }
  .footer-bottom .container-expand .footer-social i {
    font-size: 24px;
  }
  .footer-bottom .container-expand span, .footer-bottom .container-expand p {
    padding: 10px 0;
    text-align: center;
    margin: 0 auto;
  }
}
@media screen and (max-width: 1100px) {
  .developer {
    display: none;
  }
}
</style>