<template>
  <div class="loading" :class="{'is-active': $store.getters.getLoading}">
    <div class="loading-background"></div>
    <div class="loading-content">
      <img class="loading-image" src="../assets/images/loading.gif" alt="loading">
      <p class="loading-text">{{ $lang.app.loading_text }}</p>
    </div>
  </div>
</template>
<style>
.loading {
  align-items: center;
  display: none;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: fixed;
  z-index: 40;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.loading.is-active {
  display: flex;
}
.loading-background {
  background-color: rgba(10,10,10,.6);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}
.loading-content {
  padding: 20px 20px 80px;
  margin: 0 auto;
  max-height: calc(100vh - 40px);
  text-align: center;
}
.loading-image {
  width: 120px;
  margin-bottom: 10px;
}
.loading-text {
  color: white;
  font-size: 20px;
}
@media screen and (max-width: 768px) {
  .loading-content {
    margin: 0 20px;
    max-height: calc(100vh - 160px);
    overflow: auto;
    position: relative;
  }
  .loading-image {
    width: 80px;
  }
  .loading-text {
    font-size: 16px;
  }
}
</style>