<template>
  <div class="how-it-works">
    <div class="container-expand">
      <div class="how-it-works-text"><h2>{{ $lang.app.how_it_works.title }}</h2>
        <p align="center"><b>{{ $lang.app.how_it_works.des }}:</b></p></div>
      <div class="how-it-works-cards">
        <div class="how-it-works-card">
          <div class="how-it-works-card-icon how-it-works-card-icon-1"></div>
          <div class="how-it-works-card-text"><h4>{{ $lang.app.how_it_works.filling_application.title }}</h4>
            <p>{{ $lang.app.how_it_works.filling_application.des }}</p>
          </div>
        </div>
        <div class="how-it-works-card">
          <div class="how-it-works-card-icon how-it-works-card-icon-2"></div>
          <div class="how-it-works-card-text"><h4>{{ $lang.app.how_it_works.consideration_application.title }}</h4>
            <p>{{ $lang.app.how_it_works.consideration_application.des }}</p>
          </div>
        </div>
        <div class="how-it-works-card">
          <div class="how-it-works-card-icon how-it-works-card-icon-3"></div>
          <div class="how-it-works-card-text"><h4>{{ $lang.app.how_it_works.enrollment.title }}</h4>
            <p>{{ $lang.app.how_it_works.enrollment.des }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.how-it-works {
  background: #e8e8e8;
  position: relative;
}
.how-it-works:before {
  content: '';
  display: block;
  width: calc(16.6666% - 30px);
  position: absolute;
  height: 3px;
  background: url('~@/assets/images/dot.png') repeat-x;
  left: calc(25% + 45px);
  top: 46%;
}
.how-it-works .container-expand {
  padding: 95px 15px 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.how-it-works .container-expand .how-it-works-text {
  width: 33.3333%;
}
h2 {
  text-transform: uppercase;
  text-align: center;
  margin: 0 0 55px;
  font-weight: bold;
  font-size: 48px;
}
.how-it-works .container-expand .how-it-works-text p {
  margin: 0 0 80px;
}
.how-it-works .container-expand .how-it-works-cards {
  display: flex;
  justify-content: space-between;
  margin-bottom: 70px;
}
.how-it-works .container-expand .how-it-works-cards .how-it-works-card {
  width: 33.3333%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 80px;
}
.how-it-works .container-expand .how-it-works-cards .how-it-works-card .how-it-works-card-icon-1 {
  background-color: #db944b;
  background-image: url('~@/assets/images/sprite-index.png');
  background-position: -412px -5px;
}
.how-it-works .container-expand .how-it-works-cards .how-it-works-card .how-it-works-card-icon {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  background-repeat: no-repeat;
  margin-bottom: 45px;
}
.how-it-works .container-expand .how-it-works-cards .how-it-works-card h4 {
  text-align: center;
  font-size: 24px;
  margin: 0 0 25px;
  text-transform: uppercase;
  font-weight: bold;
}
.how-it-works .container-expand .how-it-works-cards .how-it-works-card p {
  font-size: 16px;
  margin: 0;
  text-align: center;
}
.how-it-works .container-expand .how-it-works-cards .how-it-works-card .how-it-works-card-icon-2 {
  background-color: #675687;
  background-image: url('~@/assets/images/sprite-index.png');
  background-position: -75px -195px;
}
.how-it-works .container-expand .how-it-works-cards .how-it-works-card .how-it-works-card-icon-3 {
  background-color: #9dc459;
  background-image: url('~@/assets/images/sprite-index.png');
  background-position: -92px -5px;
}
.how-it-works .container-expand .how-it-works-about-link {
  width: 16.6666%;
  height: 55px;
  line-height: 55px;
  border: none;
  background: linear-gradient(to bottom, #63b8d8 0%, #357cb6 100%);
  border-radius: 4px;
  overflow: hidden;
  text-align: center;
  color: #fff;
  font-weight: bold;
  padding: 0;
  margin-bottom: 25px;
  transition: 0.5s;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 15%);
  text-decoration: none;
}
.how-it-works:after {
  content: '';
  display: block;
  width: calc(16.6666% - 30px);
  position: absolute;
  height: 3px;
  background: url('~@/assets/images/dot.png') repeat-x;
  right: calc(25% + 45px);
  top: 46%;
}
.how-it-works .container-expand .how-it-works-about-link:hover {
  background: linear-gradient(to bottom, #74e8fc 0%, #375fb7 100%);
}
@media screen and (max-width: 1600px) {
  .how-it-works:before {
    left: calc(25% + 20px);
  }
  .how-it-works .container-expand {
    padding: 75px 15px;
  }
  .how-it-works .container-expand .how-it-works-text {
    width: 66.6666%;
  }
  .how-it-works .container-expand .how-it-works-cards .how-it-works-card {
    padding: 0 60px;
  }
  .how-it-works:after {
    right: calc(25% + 20px);
  }
}
@media screen and (max-width: 1200px) {
  .how-it-works:before, .how-it-works:after {
    top: 39%;
  }
  .how-it-works .container-expand {
    padding: 45px 0 50px;
  }
  h2 {
    font-size: 32px;
    margin-bottom: 35px;
  }
  .how-it-works .container-expand .how-it-works-text p {
    margin-bottom: 30px;
    line-height: 24px;
  }
  .how-it-works .container-expand .how-it-works-cards {
    margin-bottom: 45px;
  }
  .how-it-works .container-expand .how-it-works-cards .how-it-works-card {
    padding: 0 15px;
  }
  .how-it-works .container-expand .how-it-works-cards .how-it-works-card .how-it-works-card-icon-1 {
    background-image: url('~@/assets/images/sprite-index.png');
    background-position: -282px -5px;
  }
  .how-it-works .container-expand .how-it-works-cards .how-it-works-card .how-it-works-card-icon {
    width: 120px;
    height: 120px;
    margin-bottom: 30px;
  }
  .how-it-works .container-expand .how-it-works-cards .how-it-works-card h4 {
    font-size: 18px;
    margin-bottom: 15px;
  }
  .how-it-works .container-expand .how-it-works-cards .how-it-works-card .how-it-works-card-icon-2 {
    background-image: url('~@/assets/images/sprite-index.png');
    background-position: -282px -135px;
  }
  .how-it-works .container-expand .how-it-works-cards .how-it-works-card .how-it-works-card-icon-3 {
    background-image: url('~@/assets/images/sprite-index.png');
    background-position: -482px -195px;
  }
  .how-it-works .container-expand .how-it-works-about-link {
    margin-bottom: 0;
    width: 33.3333%;
  }
  .how-it-works:before, .how-it-works:after {
    top: 39%;
  }
}
@media screen and (max-width: 767px) {
  .how-it-works:before {
    width: 1px;
    height: 30px;
    left: 45px;
    top: 39%;
    background: url('~@/assets/images/dot.png') repeat-y;
  }
  .how-it-works .container-expand .how-it-works-text {
    width: 100%;
    padding: 0 15px;
  }
  .how-it-works .container-expand .how-it-works-cards {
    margin-bottom: 30px;
    flex-wrap: wrap;
  }
  .how-it-works .container-expand .how-it-works-cards .how-it-works-card {
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 20px;
  }
  .how-it-works .container-expand .how-it-works-cards .how-it-works-card .how-it-works-card-icon-1 {
    background-image: url('~@/assets/images/sprite-index.png');
    background-position: -5px -135px;
  }
  .how-it-works .container-expand .how-it-works-cards .how-it-works-card .how-it-works-card-icon {
    width: 60px;
    height: 60px;
    min-width: 60px;
    min-height: 60px;
    margin: 0 10px 0 0;
  }
  .how-it-works .container-expand .how-it-works-cards .how-it-works-card h4 {
    text-align: left;
  }
  .how-it-works .container-expand .how-it-works-cards .how-it-works-card p {
    text-align: left;
  }
  .how-it-works .container-expand .how-it-works-cards .how-it-works-card .how-it-works-card-icon-2 {
    background-image: url('~@/assets/images/sprite-index.png');
    background-position: -5px -265px;
  }
  .how-it-works .container-expand .how-it-works-cards .how-it-works-card .how-it-works-card-icon-3 {
    background-image: url('~@/assets/images/sprite-index.png');
    background-position: -412px -195px;
  }
  .how-it-works .container-expand .how-it-works-about-link {
    width: 290px;
  }
  .how-it-works:after {
    width: 1px;
    height: 30px;
    left: 45px;
    top: 56%;
    background: url('~@/assets/images/dot.png') repeat-y;
  }
}
@media screen and (max-width: 479px) {
  .how-it-works:before {
    height: 80px;
  }
  .about-us-text, .how-it-works .container-expand {
    padding: 40px 0 40px;
  }
  .how-it-works:after {
    height: 105px;
  }
}
</style>