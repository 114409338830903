export default {
    mixed: {
        required: 'Должно быть заполнено',
    },
    string: {
        required: 'Должно быть заполнено',
        min: 'Слишком короткое (минимум ${min} символов)',
        matches: 'Неправильный формат',
        email: 'Некорректный формат адреса эл.почты',
    },
    date: {
        min: 'Поле должно быть позже ${min}',
        max: 'Поле должно быть раньше ${max}',
    }
}