import { createStore } from "vuex";
import axios from "../helpers/axios";
import moment from "moment-timezone";
moment.tz.setDefault("Asia/Bishkek");
import errors from "./modules/errors";
import caution from "./modules/caution";

export default createStore({
  modules: {
    errors: errors,
    caution: caution,
  },
  state: {
    loading: false,
    token: localStorage.getItem("token") || null,
    user: null,
    role: null,
    placeOfWork: null,
    personId: null,
    organizationId: null,
    autoEnrollment: null,
    ate: null,
    deadline: null,
  },
  getters: {
    getLoading: (state) => state.loading,
    getToken: (state) => state.token,
    getUser: (state) => state.user,
    getRole: (state) => state.role,
    getPlaceOfWork: (state) => state.placeOfWork,
    getPersonId: (state) => state.personId,
    getOrganizationId: (state) => state.organizationId,
    getAutoEnrollment: (state) => state.autoEnrollment,
    getAte: (state) => state.ate,
    isAdmin: (state) => state.role === "ROLE_SUPER_ADMIN",
    isRaiono: (state) => state.role === "ROLE_ADMIN",
    isGorono: (state) =>
      [
        "ROLE_GORONO_REPUBLIC",
        "ROLE_GORONO_REGION",
        "ROLE_GORONO_DISTRICT",
      ].includes(state.role),
    isEmployee: (state) =>
      [
        "ROLE_DIRECTOR",
        "ROLE_EMPLOYEE_SCHOOL",
        "ROLE_DIRECTOR_PRESCHOOL",
        "ROLE_EMPLOYEE_PRESCHOOL",
      ].includes(state.role),
    isSchool: (state) =>
      ["ROLE_DIRECTOR", "ROLE_EMPLOYEE_SCHOOL"].includes(state.role),
    isPreschool: (state) =>
      ["ROLE_DIRECTOR_PRESCHOOL", "ROLE_EMPLOYEE_PRESCHOOL"].includes(
        state.role
      ),
    isSchoolDirector: (state) => state.role === "ROLE_DIRECTOR",
    isSchoolEmployee: (state) => state.role === "ROLE_EMPLOYEE_SCHOOL",
    isPreschoolDirector: (state) => state.role === "ROLE_DIRECTOR_PRESCHOOL",
    isPreschoolEmployee: (state) => state.role === "ROLE_EMPLOYEE_PRESCHOOL",
    isParent: (state) => state.role === "ROLE_PARENT",

    getDeadline: (state) => state.deadline,
    registrationToSchoolAvailable(state) {
      // return true;

      if (state.deadline) {
        return moment().isBetween(
          moment(state.deadline.registrationByResidenceStart),
          moment(state.deadline.registrationOutsideResidenceEnd)
        );
      }

      return false;
    },
    registrationToSchoolNotAvailable(state) {
      // return true;

      if (state.deadline) {
        return !moment().isBetween(
          moment(state.deadline.registrationByResidenceStart),
          moment(state.deadline.registrationOutsideResidenceEnd)
        );
      }

      return true;
    },
    registrationToSchoolByResidenceIsNotEnded(state) {
      // return false;

      if (state.deadline) {
        return moment().isBefore(state.deadline.registrationByResidenceEnd);
      }

      return false;
    },
    registrationToSchoolByResidenceAvailable(state) {
      // return false;

      if (state.deadline) {
        return moment().isBetween(
          moment(state.deadline.registrationByResidenceStart),
          moment(state.deadline.registrationByResidenceEnd)
        );
      }

      return false;
    },
    registrationToSchoolOutsideResidenceIsNotEnded(state) {
      // return true;

      if (state.deadline) {
        return moment().isBefore(
          state.deadline.registrationOutsideResidenceEnd
        );
      }

      return false;
    },
    registrationToSchoolOutsideResidenceAvailable(state) {
      // return true;

      if (state.deadline) {
        return moment().isBetween(
          moment(state.deadline.registrationOutsideResidenceStart),
          moment(state.deadline.registrationOutsideResidenceEnd)
        );
      }

      return false;
    },
  },
  mutations: {
    startLoading(state) {
      state.loading = true;
    },
    stopLoading(state) {
      state.loading = false;
    },
    setToken(state, payload) {
      state.token = payload.token;
    },
    setAuthData(state, payload) {
      state.user = payload.user;
      state.role = payload.role;
      state.placeOfWork = payload.placeOfWork;
      state.personId = payload.personId;
      state.organizationId = payload.organizationId;
      state.autoEnrollment = payload.autoEnrollment;
      state.ate = payload.ate;
    },
    setAutoEnrollment(state, payload) {
      state.autoEnrollment = payload;
    },
    setDeadline(state, payload) {
      state.deadline = payload.deadline;
    },
    logout(state) {
      state.token = null;
      state.user = null;
      state.role = null;
      state.placeOfWork = null;
      state.personId = null;
      state.organizationId = null;
      state.autoEnrollment = null;
      state.ate = null;
    },
  },
  actions: {
    fetchUser({ state, commit }) {
      if (state.token) {
        return axios.get(`/user/profile`).then(({ data }) => {
          commit("setAuthData", {
            user: data.user,
            role: data.role.code,
            placeOfWork: data.placeOfWork,
            personId: data.personId,
            organizationId: data.organizationId,
            autoEnrollment: data.autoEnrollment,
            ate: data.ate,
          });
        });
      }
      return new Promise((resolve) => {
        resolve();
      });
    },
    fetchDeadline({ commit }) {
      // return axios.get(`/academic-year/active`).then(({data}) => {
      //   commit('setDeadline', {
      //     deadline: data,
      //   })
      // })
      const respData = {
        id: 4,
        name: "2024-2025",
        startYear: 2024,
        endYear: 2025,
        registrationByResidenceStart: "2024-04-08 09:00:00",
        registrationByResidenceEnd: "2024-06-03 08:59:00",
        registrationOutsideResidenceStart: "2024-06-03 09:00:00",
        registrationOutsideResidenceEnd: "2024-08-30 23:59:00",
        documentSubmissionStart: "2024-08-01 09:00:00",
        documentSubmissionEnd: "2024-08-30 23:59:00",
        firstGradeEnrolmentStart: "2024-08-01 09:00:00",
        firstGradeEnrolmentEnd: "2024-09-05 18:00:00",
        registrationDeadline: "2024-09-05 18:00:00",
        goronoRegistrationStart: "2024-01-07T20:54",
        goronoRegistrationEnd: "2024-01-07T20:54",
        archived: false,
      };
      commit("setDeadline", {
        deadline: respData,
      });
    },
  },
});
