export default {
    state: {
        cautionModal: false,
        cautionTitle: null,
        cautionSubtitle: null,
    },
    mutations: {
        showCautionModal(state, payload) {
            state.cautionTitle = payload.title
            state.cautionSubitle = payload.subtitle
            state.cautionModal = true
        },
        hideCautionModal(state) {
            state.cautionTitle = null
            state.cautionSubtitle = null
            state.cautionModal = false
        },
    },
    getters: {
        getCautionModal: state => state.cautionModal,
        getCautionTitle: state => state.cautionTitle,
        getCautionSubtitle: state => state.cautionSubtitle,
    }
}