import axios from 'axios'
import qs from 'qs'
import store from '../store'

const $axios = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    paramsSerializer: params => qs.stringify(params, {arrayFormat: 'repeat'})
})

$axios.interceptors.request.use(config => {
    if(store.getters.getToken) {
        config.headers.Authorization = 'Bearer ' + store.getters.getToken;
    }
    return config;
});
$axios.interceptors.response.use(response => {
    return response;
}, async error => {
    // debugger;
    if (error.response.status === 401 || error.response.status === 403) {
        console.log('error.response.status => '+error.response.status)
        store.commit('logout')
        localStorage.removeItem('token')
        delete $axios.defaults.headers.common['Authorization']
        this.$router.push('/')
    }
    if(error.response.status === 503) {
        store.commit('showServiceErrorModal')
    }
    if(error.response.status === 422) {
        let message = error.response.data.message
        if (
            error.request.responseType === 'blob' &&
            error.response.data instanceof Blob &&
            error.response.data.type &&
            error.response.data.type.toLowerCase().indexOf('json') !== -1
        ) {
            let errorString = JSON.parse(await error.response.data.text());
            message = errorString.message
        }
        store.commit('showUnprocessableEntityErrorModal', {message})
    }
    return Promise.reject(error);
});

export default $axios
