export default {
    ru: 'русский',
    kg: 'кыргызский',
    electronic_enroll: 'Информационная система <br> по приему детей в школу и детские сады',
    helplines: 'Техническая поддержка',
    operating_mode: 'Режим работы: Пн-Пт с 09:00 до 18:00',
    main: 'Главная',
    faq: 'Вопросы/Ответы',
    school: 'Школа',
    preschool: 'Детский сад',
    statistics: 'Статистика',
    regulatory_legal_framework: 'Нормативная правовая база',
    application_apply_school: 'Подать заявку в школу',
    application_to_school: 'Подача заявки в школу',
    instruction_title: 'Инструкция',
    parent_info: 'Сведения о родителе/законном представителе ребенка',
    child_info: 'Сведения о ребенке',
    school_selection: 'Выбор школы',
    preschool_selection: 'Выбор детского сада',
    application_review: 'Просмотр заполненных сведений перед отправкой',
    application_step: 'ШАГ :step ИЗ :total',
    applicant_form_hint: 'Поля, отмеченные звездочкой (*) являются обязательными для заполнения.',
    applicant_type_label: 'Кем вы приходитесь ребенку',
    select_from: 'Выберите из списка',
    kdd_status: 'Статус КДД',
    status_kkd_true: 'дети у которых имеются документы',
    status_kkd_false: 'дети у которых отсутствуют документы',
    citizenship: 'Гражданство',
    citizen_kg: 'Я гражданин КР',
    citizen_other: 'Я гражданин другой страны',
    pin: 'ПИН',
    surname: 'Фамилия',
    only_cyrillic: 'Можно использовать только кириллицу',
    name: 'Имя',
    middle_name: 'Отчество',
    birth_date: 'День рождения',
    male: 'Мужской',
    female: 'Женский',
    passport_seria: 'Серия паспорта',
    passport_number: 'Номер паспорта',
    passport_number_des: 'В это поле необходимо ввести без пробелов номер идентификационной карты или паспорта без серии.',
    passport_number_example: 'Например: 1234567',
    country_code: 'Код страны',
    document_number: 'Номер документа',
    phone_number: 'Номер телефона',
    applicant_email: 'Электронная почта',
    // second_applicant_email: 'Электронная почта супруга',
    privilege_type: 'Тип льготы',
    applicant_privilege_type: 'Тип льготы заявителя',
    personal_data_agreement: 'Я согласен на обработку персональных данных',
    review: 'Просмотр',
    forward: 'Далее',
    new_form: 'Новая форма',
    child_gender: 'Пол ребенка',
    birth_certificate_seria: 'Серия свидетельства о рождении',
    birth_certificate_number: 'Номер свидетельства о рождении',
    delete_child: 'Удалить ребенка',
    add_child: 'Добавить ребенка',
    add_child_by_kdd: 'Добавить КДД',
    language_label: 'Желаемый язык обучения',
    region_city: 'Область/Город',
    organization_selection: 'Выбор организации',
    list_empty: 'Список пусто',
    not_found: 'Не найдено',
    review_title: 'Просмотр заполненных сведений перед отправкой',
    review_hint: 'Пожалуйста, тщательно проверьте введенные вами данные. Если вы обнаружили какую-либо ошибку, то исправьте ее.',
    applicant_full_name: 'ФИО родителя/законного представителя',
    passport_seria_and_number: 'Серия и номер паспорта',
    country_code_and_document: 'Код страны и документ',
    update_data: 'Изменить данные',
    child_full_name: 'ФИО ребенка',
    child_birth_date: 'Дата рождения ребенка',
    birth_certificate_seria_and_number: 'Серия и номер свидетельства о рождении',
    select_school_preschool: 'Выбор школы/детского сада',
    school_preschool: 'Школа/Детский сад',
    verify_submit: 'Да все верно. Подать заявку',
    application_success_title: 'Заявка успешно создана!',
    application_success_content: 'Вы можете проверить статус обработки заявления нажав на кнопку «Проверить заявку», далее указав ПИН ребенка и нажав на кнопку «Проверить»',
    to_main: 'На главную',
    write_us: 'Напишите нам',
    write_us_des: 'Если у вас остались какие-либо вопросы или вы хотите внести свои предложения и жалобы, то напишите нам используя нижеследующую форму обратной связи',
    service_error_title: 'Внимание! В настоящее время в системе проводятся внеплановые технические работы!',
    // service_error_des: 'Администратор работает над восстановлением системы. Просим повторить попытку через 15-20 минут!',
    service_error_des: 'Просим повторить попытку через 15-20 минут!',
    service_error_apologize: 'Приносим извинения за доставленные неудобства.',
    loading_text: 'Идет обработка данных',
    reset_account: 'Восстановление учетной записи',
    email_success_des: 'Сообщение с кодом активации отправлено на электронную почту:',
    reset_account_des: 'Пожалуйста, укажите фамилию, серию и номер паспорта, который Вы использовали при подачи заявления',
    cancel: 'Отмена',
    email: 'Электронная почта',
    authentication: 'Авторизация',
    username: 'Ваш логин',
    password: 'Пароль',
    login: 'Войти',
    forgot_password: 'Забыли пароль?',
    forgot_email: 'Забыли электронную почту?',
    enter_username: 'Введите Логин',
    enter_password: 'Введите Пароль',
    password_error: 'Введите на латинском',
    verify_identity: 'Вам понадобиться подтвердить личность, чтобы мы могли восстановить ваш аккаунт.',
    send_email: 'Отправить электронное письмо',
    organization: 'Организация',
    group: 'Группа',
    short_term_group: 'Краткосрочное (3-х часовое) дошкольное образование',
    privilege_file: 'Документ "Льготы"',
    kdd_file: 'Решения КДД',
    legal_representative_file: 'Документ "Законный представитель"',
    application_apply_preschool: 'Подать заявку в детсад',
    application_to_preschool: 'Подача заявки в детсад',
    application_check: 'Проверка статуса заявки',
    application_check_school: 'Проверка статуса заявки в школу',
    application_check_preschool: 'Проверка статуса заявки в детский сад',
    organization_check: 'Поиск школы',
    application_check_instruction: 'С помощью данной услуги Вы можете проверить статус обработки заявления, указав ПИН/номер свидетельства и нажав на кнопку «Проверить»',
    organization_check_instruction: 'С помощью данной услуги Вы можете узнать в какую школу вы относитесь, указав ПИН отца/матери и нажав на кнопку «Проверить»',
    child_pin: 'ПИН ребенка',
    check: 'Проверить',
    back: 'Назад',
    birth_certificate: 'Свидетельства о рождении',
    birth_certificate_des: 'В это поле необходимо ввести персональный идентификационный номер (ПИН), который можно найти на основных документах, удостоверяющих личность гражданина Кыргызской Республики.',
    birth_certificate_example: 'Например: 20101199100000',
    preschool_faq_instruction: 'Здесь Вы можете получить ответы на интересующие Вас вопросы при подачи заявки в детский сад',
    school_faq_instruction: 'Здесь Вы можете получить ответы на интересующие Вас вопросы при подачи заявки в школу',
    faq_instruction: 'Здесь вы можете получить ответы на интересующие Вас вопросы',
    faq_not_found: 'Не нашли ответ на свой вопрос?',
    faq_form_instruction: 'Напишите нам используя нижеследующую форму',
    your_name: 'Ваше имя',
    your_email: 'Ваш Е-mail',
    complaint: 'Жалоба',
    question: 'Вопрос',
    appeal: 'Обращение',
    message_text: 'Текст сообщения',
    send: 'Отправить',
    relationship_check: 'Проверить родство',
    relationship_check_instruction: 'С помощью данной услуги Вы можете проверить привязку родителя с ребенком, указав ПИН ребенка и ПИН отца/матери и нажать на кнопку «Проверить»',
    parent_pin: 'ПИН отца/матери',
    id_card_des: 'В это поле необходимо ввести персональный идентификационный номер (ПИН), который можно найти на основных документах, удостоверяющих личность гражданина Кыргызской Республики.',
    id_card_example: 'Например: 20101199100000',
    id_card: 'Идентификационная карта (2017)',
    passport: 'Паспорт (2004)',
    applicant_is_parent: 'Заявитель является :parent данного ребенка, Вы можете оформить заявку.',
    applicant_is_not_parent: 'Заявитель не является :parent данного ребенка.',
    is_father: 'отцом',
    is_mother: 'матерью',
    note_title: 'Важно!',
    relationship_check_note: 'Сведения о родстве формируются в электронном виде, начиная с 2015 года.',
    countdown_heading: 'Этапы зачисления в школы:',
    days: 'дней',
    hours: 'часов',
    minutes: 'минут',
    seconds: 'секунд',
    by_residence_countdown: 'Осталось до начала подачи заявки по 1 этапу:',
    outside_residence_countdown: 'Осталось до начала подачи заявки по 2 этапу:',
    application_not_found_text: 'По указанному ПИН, заявка отсутствует!',
    application_rejected_text: 'Ваш заявка отклонена!',
    application_waiting_text: 'Ваша электронная очередь',
    application_enroll_text: 'Ваш ребенок зачислен',
    available_organizations_map: 'Карта доступных заведений',
    login_account: 'Войти в личный кабинет',
    login_parent_account: 'Войти в кабинет родителя',
    footer_contact_text: 'В случае получения информации о коррупционных проявлениях просим вас обращаться по следующим контактам: 1222',
    technical_support: 'Техническая поддержка',
    technical_support_phone: 'Номер технической поддержки',
    work_schedule: 'График работы: понедельник-пятница с 9:00 до 18:00 часов',
    lunch_break: 'Обеденный перерыв: с 12:30 до 13:30 часов',
    feedback: 'Обратная связь',
    education_and_science_ministry: 'Министерство образования и науки Кыргызской Республики',
    developer: 'Разработчик',
    infocom: 'Государственное предприятие Инфоком',
    how_it_works: {
        title: 'Как это работает?',
        des: 'Зачисление ребенка проходит в три этапа',
        filling_application: {
            title: 'Подача заявки',
            des: 'Родитель - заявитель/законный представитель ребенка заполняет форму заявления на сайте'
        },
        consideration_application: {
            title: 'Рассмотрение заявки',
            des: 'Руководитель государственной и муниципальной общеобразовательной и дошкольной организации осуществляет проверку вашего заявления.'
        },
        enrollment: {
            title: 'Зачисление ребенка',
            des: 'После одобрения заявления, проводится зачисление ребенка.'
        },
        detailed_instructions: 'Подробные инструкции'
    },
    logout: 'Выйти',
    regulatory_legal_framework_hint: 'Здесь вы можете ознакомиться с нормативной правовой базой',
    instruction: {
        preschool: {
            title: 'Инструкция для родителей/законных представителей для подачи заявления о зачислении в дошкольные образовательные организации',
            how_to_apply: 'Как подать заявление?',
            fill_in: 'Для подачи заявления заполните последовательно следующие сведения:',
            relation_type: 'Укажите, кем вы приходитесь ребенку?',
            father: 'Отец;',
            mother: 'Мать;',
            legal_representative: 'Законный представитель (при выборе этого пункта требуется прикрепить соответствующий документ)',
            applicant_info: 'Сведения о родителе/законном представителе ребенка:',
            nationality: 'Гражданство;',
            passport_info: 'Персональный идентификационный номер (ПИН), ФИО, паспортные данные (серия, номер);',
            passport_front: 'Лицевая сторона паспорта',
            passport_back: 'Обратная сторона паспорта',
            phone: 'Номер мобильного телефона;',
            email: 'Электронная почта;',
            // extra_email: 'Дополнительная электронная почта (Электронные почты служат для восстановления учетной записи);',
            telegram_phone: 'Подключение Telegram номера (альтернативный вариант для восстановления учетной записи);',
            privilege: 'Имеется/Не имеется льгота (при выборе этого пункта требуется прикрепить соответствующий документ).',
            children_info: 'Сведения о ребенке:',
            child_info: 'Персональный идентификационный номер (ПИН), ФИО, дата рождения, пол ребенка;',
            birth_certificate_info: 'Серия, номер свидетельства о рождении;',
            birth_certificate_back: 'Обратная сторона свидетельства о рождении',
            child_privilege: 'Имеется/Не имеется льгота для ребенка на обучение (при выборе этого пункта требуется прикрепить соответствующий документ).',
            school_selection: 'Выбор детского сада:',
            language: 'Желаемый язык обучения;',
            by_address: 'Заявитель может выбирать любой детский садик.',
            preview: 'Просмотр заполненных сведений перед подачей заявления:',
            fill_in_check: 'Перед подачей заявления требуется проверить на правильность заполненные в заявлении сведения',
            completed_description: 'После подтверждения Вашего заявления Вам будет направлено на Ваш электронные почты уведомление об успешной подаче заявления для зачисления в дошкольную образовательную организацию (детский сад).',
            start_apply: 'Заполните электронное заявление, внеся следующие сведения',
        },
        school: {
            title: 'Инструкция для родителей/законных представителей для подачи заявления о зачислении в школу',
            how_to_apply: 'Как подать заявление?',
            fill_in: 'Для подачи заявления заполните последовательно следующие сведения:',
            relation_type: 'Укажите, кем вы приходитесь ребенку?',
            father: 'Отец;',
            mother: 'Мать;',
            legal_representative: 'Законный представитель (при выборе этого пункта требуется прикрепить соответствующий документ)',
            applicant_info: 'Сведения о родителе/законном представителе ребенка:',
            nationality: 'Гражданство;',
            passport_info: 'Персональный идентификационный номер (ПИН), ФИО, паспортные данные (серия, номер);',
            passport_front: 'Лицевая сторона паспорта',
            passport_back: 'Обратная сторона паспорта',
            phone: 'Номер мобильного телефона;',
            email: 'Электронная почта;',
            // extra_email: 'Дополнительная электронная почта (Электронные почты служат для восстановления учетной записи);',
            telegram_phone: 'Подключение Telegram номера (альтернативный вариант для восстановления учетной записи);',
            privilege: 'Имеется/Не имеется льгота (при выборе этого пункта требуется прикрепить соответствующий документ).',
            children_info: 'Сведения о ребенке:',
            child_info: 'Персональный идентификационный номер (ПИН), ФИО, дата рождения, пол ребенка;',
            birth_certificate_info: 'Серия, номер свидетельства о рождении;',
            birth_certificate_back: 'Обратная сторона свидетельства о рождении',
            child_privilege: 'Имеется/Не имеется льгота для ребенка на обучение.',
            school_selection: 'Выбор школы:',
            by_first_lap: 'По первому этапу:',
            language: 'Желаемый язык обучения;',
            by_address: 'По адресу прописки родителей в системе автоматически отображается соответствующая школа.',
            by_second_lap: 'По второму этапу:',
            region_city: 'Область/город',
            applicant_can_select: 'Заявитель может выбирать любую школу, при наличии свободных мест.',
            preview: 'Просмотр заполненных сведений перед подачей заявления:',
            fill_in_check: 'Перед подачей заявления требуется проверить на правильность заполненные в заявлении сведения',
            completed_description: 'После подтверждения Вашего заявления Вам будет направлено на Ваш электронные почты уведомление об успешной подаче заявления для зачисления в школу.',
            start_apply: 'Заполните электронное заявление, внеся следующие сведения',
        }
    },

    application_info: 'Данные заявки',
    applicant_info: 'Данные родителя',
    children_info: 'Данные ребенка',
    change_school: 'Поменять школу',
    change_preschool: 'Поменять детский сад',
    enroll: 'Зачислить',
    reject: 'Отклонить',
    archive: 'Архивировать',
    drop: 'Отчислить',
    application_type: 'Тип',
    application_status: 'Статус',
    application_comment: 'Комментарий',
    education_language: 'Язык обучения',
    school_or_preschool: 'Школа/Детский сад',
    archived: 'Архивирован',
    yes: 'Да',
    no: 'Нет',
    archived_comment: 'Отметка архивации',
    to_school: 'В школу',
    to_preschool: 'В детский сад',
    applicant_pin: 'ПИН родителя/законного представителя',
    applicant_fullname: 'ФИО родителя/законного представителя',
    child_privilege_type: 'Тип льготы ребенка',
    update: 'Обновить',
    comment: 'Отметки',
    submit: 'Подтвердить',
    applications: 'Заявки',
    number_or_pin: '№/ПИН',
    applicant_address: 'Адрес заявителя',
    address: 'Адрес',
    user_address_not_found: 'Адрес пользователя не найден',
    child_citizenship: 'Место выдачи свидетельства о рождении:',
    child_citizen_kg: 'Кыргызская Республика',
    child_citizen_foreign: 'За пределами Кыргызской Республики',
    view: 'Просмотр',
    title: 'Наименование',
    start_year: 'Начало учебного года',
    end_year: 'Окончание учебного года',
    registration_by_residence_start: 'Начало регистрации по местожительству',
    registration_by_residence_end: 'Окончание регистрации по местожительству',
    registration_outside_residence_start: 'Начало регистрации вне местожительства',
    registration_outside_residence_end: 'Окончание регистрации вне местожительства',
    document_submission_start: 'Начало подтверждения и предоставления документов',
    document_submission_end: 'Окончание подтверждения и предоставления документов',
    first_grade_enrollment_start: 'Начало зачисления в 1 класс',
    first_grade_enrollment_end: 'Окончание зачисления в 1 класс',
    registration_deadline: 'Окончание регистрации',
    gorono_registration_start: 'Функционал по добавлению детей в школу для районо (начало)',
    gorono_registration_end: 'Функционал по добавлению детей в школу для районо (конец)',
    create: 'Создать',
    resource_created_successfully: 'Запись успешно создана!',
    edit: 'Редактировать',
    add_applicant: 'Добавить данные заявителя',
    deadline_info: 'Данные срока подачи заявки',
    resource_updated_successfully: 'Запись успешно обновлена!',
    deadlines: 'Сроки подачи заявки',
    delete: 'Удалить',
    resource_deleted_successfully: 'Запись успешна удалена!',
    delete_submission_text: 'Вы действительно хотите безвозвратно удалить этот запись!',
    category: 'Категория',
    answer: 'Ответ',
    creation: 'Создание',
    position_quantity: 'Количество мест',
    quantity: 'Количество',
    groups: 'Группы',
    editing: 'Редактирование',
    heading: 'Заголовка',
    attach_file: 'Прикрепить файл',
    law: 'НПА',
    file: 'Файл',
    file_message: 'Документ необходимо прикрепить в виде файла в формате pdf',
    legal_act: 'Нормативная правовая база',
    quota: 'Квота',
    quotas: 'Квоты',
    user_info: 'Данные пользователя',
    fullname: 'ФИО',
    reset_password: 'Сбросить пароль',
    users: 'Пользователи',
    schools: 'Школы',
    preschools: 'Детские сады',
    enrolled: 'Зачисленные',
    waiting: 'Ожидающие очереди',
    rejected: 'Отклоненные',
    archiveds: 'Архивированные',
    students: 'Список зачисленных детей',
    transform: 'Перевод',
    student_info: 'Данные ученика',
    enroll_date: 'Дата зачисления',
    enrolled_to_first_grade: 'Зачисленные в 1 класс',
    account_school: 'Личный кабинет школы',
    account_preschool: 'Личный кабинет детского сада',
    auto_enrollment: 'Авто зачисление',
    auto_enrollment_on: 'Включен режим автоматического зачисления',
    auto_enrollment_off: 'Отключен режим автоматического зачисления',
    application_created_date: 'Дата подачи заявки',

    ordinary: 'Безльготник',
    privileged: 'Льготник',
    waiting_for_enroll_school: 'Ожидающие зачисления по льготе и «Законный представитель»',

    free_quotas: 'Свободные места',
    available_quotas: 'Свободные места. <br> Для льготников/ <br> без льготников',
    total_quota: 'Общая количество квот',
    total_ordinary_quota: 'Количество квот для безльготников',
    total_privilege_quota: 'Количество квот для льготников',
    total_enrolled_quota: 'Общая количество зачисленных',
    total_ordinary_enrolled_quota: 'Количество зачисленных безльготников',
    total_privilege_enrolled_quota: 'Количество зачисленных льготников',
    total_available_quota: 'Общая количество свободных мест',
    total_ordinary_available_quota: 'Количество свободных мест для безльготников',
    total_privilege_available_quota: 'Количество свободных мест для льготников',

    student_successfully_archived: 'Ученик успешно архивирован!',
    raion: 'Район',
    aimak: 'Аймак',
    decree: 'Приказ',
    print: 'Печать',
    employee_account: 'Личный кабинет сотрудника',
    parent_account: 'Личный кабинет родителя',
    inquiry: 'Справка',
    motive: 'Мотив',
    in_process: 'В процессе обработки',

    address_not_found_error: 'У вас отсутствует адрес регистрации. Вам необходимо обратиться в ближайший ЦОН для уточнения адреса.<br>' +
        'В случае, если Вы являетесь собственником жилья, при себе иметь:<br>' +
        '<br>' +
        'Паспорт;<br>' +
        'Технический паспорт недвижимости.<br>' +
        'В случае, если Вы не являетесь собственником жилья, подать заявление о регистрации Вас по адресу проживания должен владелец жилья при личном присутствии.<br>' +
        'При себе иметь:<br>' +
        '<br>' +
        'Паспорт;<br>' +
        'Паспорт владельца жилья;<br>' +
        'Технический паспорт недвижимости.',
    organization_not_found_error: 'По Вашей юридической прописке отсутствует школа. Ваш прописанный адрес в школу, отправлено в техническую поддержку. Наши специалисты добавят Ваш адрес в базу и через некоторое время можете проверить в разделе «Поиск школы».',
    privileged_enrolled: 'Зачислены льготники',
    total_enrolled: 'Зачислены',
    application_enrolled: 'Успешно зачислен',
    quota_empty: 'Не осталось свободных мест',
    total_application_quantity: 'Общее количество заявок',
    waiting_for_enroll: 'Ожидающие очереди',
    waiting_for_enrolling: 'Ожидающие зачисления',
    as_legal_representative: '«Законный представитель»',
    reports: 'Отчеты',
    date_from: 'С',
    date_to: 'По',
    download: 'Скачать',
    report_type: 'Тип отчета',
    school_report: 'Отчет по ОО',
    preschool_report: 'Отчет по ДОО',
    school_report_by_district: 'Отчет по ОО по району',
    preschool_report_by_district: 'Отчет по ДОО по району',
    school_statistics_report: 'Сведения о количестве заявок в ОО по области',
    successfully_archived: 'Архивация успешно выполнено',
    successfully_dropped: 'Отчисление успешно выполнено',
    unsuccessfully_dropped: 'Отчисление не успешно',
    search: 'Поиск',
    transfer: 'Перевод',
    successfully_transfered: 'Успешно переведен',
    unarchive: 'Разархивировать',
    successfully_unarchived: 'Разархивация успешно выполнено',
    transfer_students: 'Перевод учеников группы',
    restore: 'Восстановить',
    successfully_restored: 'Восстановление успешно выполнено',
    application_enrolled_date: 'Дата зачисления',
    privilege: 'Льгота',
    status_application: 'Статус заявки',
    check_application_status_school: 'Проверить статус заявки в школу',
    check_application_status_preschool: 'Проверить статус заявки в детский сад',
    or: 'или',
    application_not_found: 'Заявка не найдена',
    recheck_entered_data: 'Перепроверьте введенные данные',
    return_back: 'Вернуться назад',
    application_rejected: 'Заявка отклонена',
    application_dropped: 'Отчислен',
    attention: 'Внимание!',
    child_age: 'Возраст ребенка',
    child_birth_year: 'Год рождения',
    selected_preschool: 'Выбранный детский сад',
    selected_preschool_address: 'Адрес ДОО:',
    status: 'Статус',
    application_waiting: 'Ожидает рассмотрения',
    status_queue: 'Ваша очередь :queue из :total',
    application_enroll: 'Зачислен в',
    status_enroll: 'Зачислен',
    status_waiting: 'Ожидает очереди',
    status_rejected: 'Отклонен',
    status_archived: 'Архивирован',
    help: 'Помощь',
    certificate: 'Аттестат',
    content: 'Содержание',
    content_type: 'Тип содержание',
    document: 'Документ',
    link: 'Ссылка',
    must_be_filled: 'Должно быть заполнено',
    application_number: 'Номер заявки',
    reset: 'Очистить',
    your_queue: 'Ваша заявка находится на :queue-месте в общей очереди',
    documents: 'Документы',
    edit_privileges: 'Редактировать льготы',
    successfully_canceled: 'Заявка успешно отклонено',
    to_cancel: 'Отменить заявку',
    cancel_confirm_text: 'Вы уверены, что хотите отменить заявку?',
    preschool_faq_description: 'Ответы на часто задаваемые вопросы при подачи заявки в детский сад',
    school_faq_description: 'Ответы на часто задаваемые вопросы при подачи заявки в школу',
    regulatory_legal_framework_description: 'Здесь вы можете ознакомиться с нормативной правовой базой',
    frequently_asked_questions: 'Часто задаваемые вопросы',
    video_description: 'Электронная запись в первый класс государственных и муниципальных школ',
    video_link: 'https://www.youtube.com/watch?v=JxOWPstGOTk',
    statistics_instruction: 'С помощью данной услуги Вы можете узнать информацию о количестве квоты, зачисленных детей, свободные места и ожидающие к зачислению детей. Для этого Вам необходимо выбрать соответствующий раздел (Детский сад или Школу), выбрать область, район, и выбрать организацию.',
    technical_support_all: '<h5>Техническая поддержка:</h5>',
    application_waiting_director_confirm: 'Ваша заявка находится на рассмотрении у директора',
    rejected_time_text: 'Заявка отклонена от :date, время: :time.',
    application_queue_caution_title: 'В настоящее время ведутся работы по обновлению сведений об очередности. Просим подождать, в скором времени Вы сможете проверить статус Вашей заявки и номер в очереди. Спасибо за понимание!',
    check_by_pin: 'Проверка по ПИН',
    check_by_birth_certificate: 'Проверка по свидетельству о рождении',
    check_by_fio: 'Проверка по ФИО',
    application_school_successfully_changed: 'Школа заявки успешно изменена!',
    application_preschool_successfully_changed: 'Детский сад заявки успешно изменена!',
    application_language_successfully_changed: 'Язык обучения заявки успешно изменена!',
    warning: 'Предупреждение!!!',
    application_cancel_confirm_text: 'Уважаемый (ая) :fio, Вы отзываете заявление, поданное в :organization. Если Вы согласны, нажмите кнопку «Подтвердить».',
    application_cancel_cancel_text: 'После отзыва заявления, ребенок теряет место (очередь). Если Вы не согласны, нажмите кнопку "Отмена".',
    application_cancel_warning_text: 'Позже, при повторной подаче заявки, необходимо будет заново заполнить все данные.',
    confirm: 'Подтвердить',
    application_school_change_confirm_question: 'Вы действительно хотите поменять школу?',
    application_preschool_change_confirm_question: 'Вы действительно хотите поменять детский сад. При изменении детского сада потеряется место в очереди',
    application_organization_language_change_confirm_question: 'Вы действительно хотите поменять язык обучения.',
    change: 'Изменить',
    wrong_email_note: 'Если Вы указали электронную почту не верно при регистрации заявки, Вам необходимо обратиться в ближайший районный центр образования МОН КР.',
    your_group: 'Ваша очередь в группу',
    application_queue_note: 'Ваша позиция в электронной очереди может измениться, в связи с тем, что заявления по льготе рассматриваются и принимаются в первоочередном порядке.',
    file_size_limit_validation: 'Превышен максимальный размер файла (3 мб)',
    waiting_enrolling: 'Ожидающие зачисление',
    checked: 'Проверенные',
    expired: 'Просроченные',
    frozen: 'Замороженные',
    canceled: 'Отмененные',
    dropped: 'Отчисленные',
    application_waiting_enrolling: 'Ожидает зачисления',
    auto_enrollment_confirm_question: 'Вы действительно хотите включить?',
    change_language: 'Поменять язык обучения',
    enroll_comment_label: 'Указать дату и номер приказа о зачислении',
    restore_to_waiting: 'Восстановить в ожидающие очереди',
    restore_to_waiting_confirm_question: 'Вы действительно хотите восстановить в ожидающие очереди?',
    successfully_restored_to_waiting: 'Успешно востановлено в ожидающие осереди!',
    restore_to_waiting_enrolling: 'Восстановить в ожидающие зачисления',
    restore_to_waiting_enrolling_confirm_question: 'Вы действительно хотите восстановить в ожидающие зачисления?',
    successfully_restored_to_waiting_enrolling: 'Успешно востановлено в ожидающие зачисления!',
    username_hint: 'Пример ввода логина',
    document_brought: 'Предоставлены документы',
    document_brought_on: 'Отмечен принес документы',
    document_brought_off: 'Незамечен принес документы',
    document_brought_confirm_question: 'Вы действительно хотите отметить принес документы?',
    send_to_emis: 'Отправить данные в ИСУО',
    sent_emis: 'Отправленные в ИСУО',
    send_to_emis_title: 'Приказ о зачислении',
    send_to_emis_label: 'О принятии в 1 класс общеобразовательной организации',
    emis_yes: 'Отправить данные в ИСУО',
    emis_no: 'Отменить',
    from_message: 'из',
    get_on_message: 'Принять',

    statistic: {
        region_city: 'Область/город',
        amount_preschool: 'Количество ДОО',
        amount_school: 'Количество ОО',
        number_of_quota: 'Количество квот',
        number_of_enrolled_child: 'Количество обучающихся детей (зачисленные)',
        number_of_enrolled_child_in_the_school: 'Количество зачисленных детей',
        number_of_free_places: 'Количество свободных мест',
        number_of_waiting_app: 'Количество заявок (ожидающие очереди)',
        number_of_waiting_app_in_the_school: 'Ожидающие зачисления «Законный представитель»',
        number_of_waiting_privileges_app: 'Из них льготники',
        number_of_waiting_privileges_app_in_the_school: 'Ожидающие зачисления по льготе',
        district_city: 'Район/город',
        preschool_name: 'Наименование ДОО',
        school_name: 'Наименование ОО',
    },
}