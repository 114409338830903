<template>
  <div class="main-info">
    <div class="container-expand">
      <div class="main-info-left" :key="countdown">
        <div>
          <h3>{{ $lang.app.countdown_heading }}</h3>
          <div class="main-info-left-links">
            <div v-if="$store.getters.registrationToSchoolByResidenceIsNotEnded" class="countdown-wrapper">
              <label v-if="!$store.getters.registrationToSchoolByResidenceAvailable" class="countdown-label">
              <span>
                {{ $lang.app.by_residence_countdown }}
              </span>
              </label>
              <div class="countdown-content">
                <router-link v-if="$store.getters.registrationToSchoolByResidenceAvailable"
                             to="/application-school" class="main-info-link apply">
                  {{ $lang.app.application_apply_school }}
                </router-link>
                <Countdown v-else
                           :deadline="$store.getters.getDeadline.registrationByResidenceStart"
                           :labels="countdownLabels"
                           labelColor="#fff"
                           mainColor="#fff"
                           secondFlipColor="#fff"
                           mainFlipBackgroundColor="#63b8d8"
                           secondFlipBackgroundColor="#367db7"
                           countdownSize="2.4rem"
                           labelSize="1rem"
                           @timeElapsed="updateDeadline"
                />
              </div>
            </div>
            <div v-if="$store.getters.registrationToSchoolOutsideResidenceIsNotEnded" class="countdown-wrapper">
              <label v-if="!$store.getters.registrationToSchoolOutsideResidenceAvailable" class="countdown-label">
              <span>
                {{ $lang.app.outside_residence_countdown }}
              </span>
              </label>
              <div class="countdown-content">
                <router-link v-if="$store.getters.registrationToSchoolOutsideResidenceAvailable"
                             to="/application-school" class="main-info-link apply">
                  {{ $lang.app.application_apply_school }}
                </router-link>
                <div v-else>
                  <Countdown :key="countdown"
                             :deadline="$store.getters.getDeadline.registrationOutsideResidenceStart"
                             :labels="countdownLabels"
                             labelColor="#fff"
                             mainColor="#fff"
                             secondFlipColor="#fff"
                             mainFlipBackgroundColor="#63b8d8"
                             secondFlipBackgroundColor="#367db7"
                             countdownSize="2.4rem"
                             labelSize="1rem"
                             @timeElapsed="updateDeadline"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="main-info-left-map">
          <a href="https://open.edu.gov.kg/index.php">
            <i class="bi-geo-alt-fill"></i>
            {{ $lang.app.available_organizations_map }}
          </a>
        </div>
      </div>
      <div class="main-info-right">
        <router-link to="/application-preschool"
                     class="sign-in button-green">
          {{ $lang.app.application_apply_preschool }}
        </router-link>
        <router-link v-if="$store.getters.getUser" to="/admin" class="sign-in button-blue">
          {{ $lang.app.login_parent_account }}
        </router-link>
        <router-link v-else to="/auth/login-parent" class="sign-in button-blue">
          {{ $lang.app.login_parent_account }}
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import {Countdown} from 'vue3-flip-countdown'

export default {
  components: {
    Countdown
  },
  data() {
    return {
      countdown: 1
    }
  },
  computed: {
    countdownLabels() {
      return {
        days: this.$lang.app.days,
        hours: this.$lang.app.hours,
        minutes: this.$lang.app.minutes,
        seconds: this.$lang.app.seconds,
      }
    }
  },
  methods: {
    updateDeadline() {
      this.countdown++
    }
  },
  created() {
    this.$emitter.on('langChanged', () => {
      this.countdown++
    })
    setInterval(() => {
      this.countdown++
    }, 60000);
  }
}
</script>
<style scoped>
.countdown-wrapper {
  display: flex;
  align-items: center;
  padding: 10px 0;
  justify-content: center;
}
.countdown-label {
  width: 40%;
  color: white;
  font-size: 20px;
  margin-bottom: 28px;
  font-weight: bold;
  text-align: end;
  padding-right: 20px;
}
.countdown-content {
  display: flex;
  justify-content: center;
}
.main-info {
  background: url('~@/assets/images/main-bg.jpg') no-repeat center center;
  background-size: cover;
}
.main-info .container-expand {
  display: flex;
  padding: 0;
}
.main-info-left {
  width: 76.7777%;
  padding: 55px 55px 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  justify-content: center;
}
.main-info-left h3 {
  color: #fff;
  font-weight: bold;
}
.main-info-left-links {
  background: rgba(0,0,0,0.5);
  border-radius: 6px;
  padding: 28px 0 10px;
}
.main-info-left p {
  font-size: 30px;
  color: #fff;
  line-height: 42px;
  margin: 0 0 50px;
  text-align: center;
}
.main-info-left .apply {
  background: linear-gradient(to bottom, #63b8d8 0%, #367db7 100%);
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 15%);
  margin-right: 5px;
  margin-left: 5px;
}
.main-info-left .main-info-link {
  width: 260px;
  height: 55px;
  line-height: 55px;
  text-align: center;
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  border-radius: 5px;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 20px;
}
.main-info-left .main-info-left-map {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  height: 75px;
  line-height: 75px;
  text-align: center;
  background: rgba(0,0,0,0.5);
}
.main-info-left .main-info-left-map a {
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-info-left .main-info-left-map a i {
  font-size: 36px;
  text-decoration: none;
  margin-right: 15px;
}
.main-info-right {
  width: 33.3333%;
  background: rgba(103,86,135,0.5);
  padding: 110px 100px 100px;
  display: flex;
  flex-direction: column;
  min-height: 450px;
  justify-content: center;
  align-items: center;
}
.sign-in {
  font-weight: bold;
  color: #fff;
  display: inline-block;
  width: 100%;
  height: 55px;
  line-height: 55px;
  overflow: hidden;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 15%);
  transition: 0.5s;
  margin-bottom: 20px;
}
.sign-in.button-green {
  background: linear-gradient(to bottom, lightseagreen 0%, lightseagreen 100%);
}
.sign-in.button-green:hover {
  background: linear-gradient(to bottom, darkseagreen 0%, lightseagreen 100%);
}
.sign-in.button-transparent {
  border: 3px solid #fff;
  line-height: 49px;
}
.sign-in.button-transparent:hover {
  text-decoration: underline;
}
.sign-in.button-blue {
  background: linear-gradient(to bottom, #63b8d8 0%, #367db7 100%);
}
.sign-in.button-blue:hover {
  background: linear-gradient(to bottom, #74e8fc 0%, #375fb7 100%);
}
.main-info-left .apply:hover {
  background: linear-gradient(to bottom, #74e8fc 0%, #375fb7 100%);
}
.main-info-left .check:hover {
  text-decoration: underline;
}
.main-info-left .main-info-left-map a:hover {
  text-decoration: underline;
}
@media screen and (max-width: 1600px) {
  /*.main-info-left {*/
  /*  padding: 65px 85px 0;*/
  /*}*/
  .main-info-left h1 {
    line-height: 1.3;
    font-size: 42px;
  }
  .main-info-left p {
    font-size: 24px;
    line-height: 32px;
  }
  .main-info-right {
    padding: 55px 55px 65px;
  }
}
@media screen and (max-width: 1200px) {
  .main-info .container-expand {
    padding: 0;
    flex-wrap: wrap;
  }
  .main-info-left {
    width: 100%;
    padding: 65px;
  }
  .main-info-left h1 {
    text-align: center;
    width: 100%;
    padding: 0 80px;
    line-height: 1.2;
    margin-bottom: 40px;
  }
  .main-info-left p {
    font-size: 18px;
    margin: 0 0 40px;
    line-height: 24px;
  }
  .main-info-left .main-info-left-map {
    height: 65px;
    line-height: 65px;
    display: none;
  }
  .main-info-left .main-info-left-map a i, .regulatory .container-expand .regulatory-items .regulatory-item a i {
    font-size: 24px;
  }
  .main-info-right {
    width: 100%;
    min-height: auto;
    padding: 55px 20px;
  }
  .sign-in {
    max-width: 340px;
    margin-right: 0;
  }
}
@media screen and (max-width: 767px) {
  .countdown-wrapper {
    flex-direction: column;
    align-items: center;
  }
  .countdown-label {
    width: 100%;
    padding-right: 0;
    margin-bottom: 10px;
    text-align: center;
  }
  .main-info-left {
    padding: 40px 15px;
  }
  .main-info-left h1 {
    padding: 0 30px;
    font-size: 36px;
  }
  .main-info-left p {
    padding: 0 30px;
  }
  .main-info-left-links {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
  }
}
@media screen and (max-width: 479px) {
  .main-info-left {
    padding: 30px 15px;
  }
  .main-info-left h1 {
    font-size: 24px;
    padding: 0 15px;
    margin-bottom: 30px;
  }
  .main-info-left p {
    font-size: 16px;
    padding: 0 15px;
    margin-bottom: 30px;
  }
  .main-info-left-links {
    flex-wrap: wrap;
  }
  .main-info-left .main-info-link {
    width: 100%;
    padding: 0 20px;
  }
  .apply {
    margin-bottom: 20px;
  }
}
</style>